<template>
  <div
    v-if="!user.signedUp"
    class="RMP-UserWidget p-lg m-t-lg m-b-lg is-in-main--content"
  >
    <div
      v-if="!user.success"
      class="RMP-UserWidget--Form"
    >
      <h5 class="RMP-UserWidget--Title has-text-centered is-size-4 has-text-weight-bold m-b-sm">
        {{ displayTitle }}
      </h5>
      <p class="RMP-UserWidget--Description has-text-centered ">
        {{ displayDescription }}
      </p>

      <div class="RMP-UserWidget--Form">
        <form
          method="post"
          @submit.prevent="submit"
        >
          <div class="field has-addons">
            <div
              class="control has-icons-left is-expanded m-b-md"
            >
              <input
                v-model="form.email"
                :class="{ 'is-danger': validation.email }"
                class="input is-info RMP-UserWidget--FormInput"
                type="email"
                placeholder="Enter your email address"
              >
              <span class="icon is-small is-left RMP-UserWidget--Icon">
                <i class="fa fa-envelope" />
              </span>
              <p class="help is-danger">
                {{ validation.email }}
              </p>
            </div>
            <div class="control">
              <button
                :class="{ 'is-loading': ui.loading }"
                class="button is-primary RMP-UserWidget--Button"
              >
                Sign Up!
              </button>
            </div>
          </div>
        </form>
      </div>

      <p class="RMP-UserWidget--Optin has-text-centered">
        {{ displayDisclaimer }}
      </p>
    </div>
    <div
      v-else
      class="RMP-UserWidget--Success"
    >
      <span class="icon is-large">
        <i class="fa fa-smile-beam fa-3x" />
      </span>
      <p>{{ displaySuccess }}</p>
    </div>
  </div>
</template>

<script>
import userMixin from './user-mixin';

export default {
  mixins: [userMixin],
};
</script>
