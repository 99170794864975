// This will be superseded by resources/assets/vue/blog/blog.js
// once the blog is integrated into rate fully.

import { createApp } from 'vue';

import './polyfills';
import { bugsnagClient } from './bugsnag';
import EventBusPlugin from './event-bus';
import GaPlugin from './google-analytics';
import filterPlugin from './filters';
import store from './vuex/index';

import vueJobWidget from './components/jobs-widget';
import vueUserWidget from './components/user-widget';
import vueUserSidebarWidget from './components/user-widget/sidebar';

import '@/vanilla/flash-messages';
import '@/vanilla/mobile-menu';
import '@/vanilla/search/global-autocomplete';

function bindGlobalVueProps(appInstance) {
  appInstance.config.globalProperties.$bus = EventBusPlugin;
  appInstance.config.globalProperties.$bugsnag = bugsnagClient;
  appInstance.config.globalProperties.$ga = GaPlugin;
  appInstance.use(filterPlugin);
  // appInstance.config.globalProperties.$axios = AxiosPlugin;

  if (appInstance.config.globalProperties.RMP_ENVIRONMENT === 'production') {
    appInstance.use(bugsnagClient.getPlugin('vue'));
  }
}

/* eslint-disable no-new */
const jobWidgets = document.querySelectorAll('.vue-jobs-widget');

Array.from(jobWidgets).forEach((jobWidget) => {
  const jobWidgetsApp = createApp({
    el: jobWidget,
    components: {
      'vue-jobs-widget': vueJobWidget,
    },
  });
  jobWidgetsApp.use(store);
  bindGlobalVueProps(jobWidgetsApp);
  jobWidgetsApp.mount(jobWidget);
});

const userWidgets = document.querySelectorAll('.vue-user-widget');

Array.from(userWidgets).forEach((userWidget) => {
  const userWidgetsApp = createApp({
    el: userWidget,
    components: {
      'vue-user-widget': vueUserWidget,
    },
  });

  userWidgetsApp.use(store);
  bindGlobalVueProps(userWidgetsApp);
  userWidgetsApp.mount(userWidget);
});

const userSidebarWidgets = document.querySelectorAll('.vue-user-sidebar-widget');

Array.from(userSidebarWidgets).forEach((userWidget) => {
  const userSidebarWidgetsApp = createApp({
    el: userWidget,
    components: {
      'vue-user-sidebar-widget': vueUserSidebarWidget,
    },
  });
  userSidebarWidgetsApp.use(store);
  bindGlobalVueProps(userSidebarWidgetsApp);
  userSidebarWidgetsApp.mount(userWidget);
});
