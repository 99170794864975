<template>
  <a
    :href="result.url"
    class="HotJob-job RMP-JobsWidget--Job p-md"
  >
    <div class="HotJob-link">
      <figure class="Logo">
        <img
          v-if="result.company.logo"
          :src="result.company.logo"
          :alt="result.company.name"
          class="Logo-image"
          width="60"
          height="60"
        >
        <img
          v-else
          :src="defaultLogo"
          :alt="result.company.name"
          class="Logo-image"
          width="60"
          height="60"
        >
      </figure>
      <div class="HotJob-specs">
        <h3
          class="HotJob-title"
          style="margin: 0"
        >
          {{ result.title }}
        </h3>
        <span class="RMP-JobsWidget--JobCompanyName companyName">
          {{ result.company.name }}
        </span>
        <span class="Tag Tag--blue HotJob-JobTag">{{ $filters.trim(jobTypeText, 37) }}</span>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: 'JobResult',
  props: {
    result: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      defaultLogo: 'https://dbx9jsyriv02l.cloudfront.net/assets/placeholder.png',
    };
  },

  computed: {
    jobTypeText() {
      return this.result.job_type_text;
      // return this.result.job_type_text ? this.result.job_type_text : this.result.duration_text;
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>

</style>
