<template>
  <div
    v-if="ui.showWidget"
    class="RMP-JobsWidget"
  >
    <h3 class="RMP-JobsWidget--Title has-text-weight-bold is-size-5 m-b-sm has-text-centered">
      {{ displayTitle }}
    </h3>
    <p
      v-if="displayDescription"
      class="has-text-centered m-b-sm"
    >
      {{ displayDescription }}
    </p>
    <div class="RMP-JobsWidget--Results Hotjob">
      <component
        :is="resultTemplate"
        v-for="result in computedResults"
        :key="result.id"
        :result="result"
      />
    </div>
    <div class="RMP-JobsWidget--Footer">
      <p>
        <a
          :href="requestUrl"
          class="button is-primary m-b-sm"
        >View all related jobs ({{ remote.count }})</a>
      </p>
      <div v-if="showLinks">
        <p>
          <a
            v-if="!ui.loading"
            href="#"
            @click.prevent="ui.viewLinks = !ui.viewLinks"
          >
            Not what you're looking for?
          </a>
        </p>
        <div
          v-if="ui.viewLinks"
          class="RMP-JobsWidget--Links"
        >
          <ul>
            <li
              v-for="category in remote.categories"
              :key="category.key"
            >
              <a
                :href="altLink(category.key)"
                class="p-sm"
              >{{ category.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import jobResult from './items/job-result';
import placeHolderResult from './items/placeholder-result';

export default {
  props: {
    displayTitle: {
      type: String,
      default: 'Top Placements, Internships & Insights',
    },
    displayDescription: {
      type: String,
      default: null,
    },
    displayLimit: {
      type: Number,
      default: 6,
    },
    searchUrl: {
      type: String,
      default: '/search?show=jobs',
    },
    showLinks: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapState('jobs', {
      config: (state) => state.config,
      remote: (state) => state.remote,
      ui: (state) => state.ui,
    }),

    requestUrl() {
      return `${this.config.base}${this.searchUrl}`;
    },

    computedResults() {
      if (!this.remote.results.data && this.ui.loading) {
        return new Array(this.displayLimit).fill({});
      }
      if (!this.remote.results.data) {
        return [];
      }
      return this.remote.results.data.slice(0, this.displayLimit);
    },

    resultTemplate() {
      if (this.ui.loading) {
        return placeHolderResult;
      }
      return jobResult;
    },
  },

  mounted() {
    this.getResults();
  },

  methods: {
    ...mapActions('jobs', {
      storeGetJobs: 'getJobs',
    }),

    getResults() {
      this.storeGetJobs({ searchUrl: this.searchUrl });
    },

    altLink(key) {
      return `${this.config.base}search?show=jobs&roles_parent=${key}`;
    },
  },

};
</script>
<style lang="scss" scoped>

</style>
