function setupMobileMenu() {
  // General Overlay
  const $overlay = document.querySelector('.MenuOverlay');

  // Global - Show the mobile menu
  const $mobileMenuTrigger = document.querySelector('.mobile-menu');
  const $bodySelector = document.querySelector('body');
  const $mainMenu = document.querySelector('.js-navigation');

  function closeMenu(element) {
    $bodySelector.style.height = 'auto';
    $bodySelector.style.overflow = 'auto';

    element.classList.remove('menu-open');
    $overlay.classList.remove('menu-open');
    $bodySelector.classList.remove('menu-open');
  }

  function openMenu(element) {
    const windowHeight = window.innerHeight;
    $bodySelector.style.overflow = 'hidden';
    $bodySelector.style.height = `${windowHeight}px`;

    $bodySelector.classList.add('menu-open');
    $overlay.classList.add('menu-open');
    element.classList.add('menu-open');
  }

  $mobileMenuTrigger.addEventListener('click', (e) => {
    e.preventDefault();
    if ($mainMenu.classList.contains('menu-open')) {
      closeMenu($mainMenu);
    } else {
      openMenu($mainMenu);
    }
  });

  $overlay.addEventListener('click', (e) => {
    e.preventDefault();
    const $openedItems = document.querySelectorAll('.menu-open');
    $openedItems.forEach(($item) => {
      closeMenu($item);
    });
  });
}

if (document.querySelector('.mobile-menu') !== null) {
  setupMobileMenu();
}
