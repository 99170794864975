import axios from '@/axios';

function getJobTypeSuggestionsParams() {
  const pathName = window.location.pathname;

  if (pathName.includes('search-jobs')) {
    return {
      params: {
        target: 'jobs',
      },
    };
  } if (pathName.includes('search-companies')) {
    return {
      params: {
        target: 'companies',
      },
    };
  } if (pathName.includes('search-reviews')) {
    return {
      params: {
        target: 'reviews',
      },
    };
  } if (pathName.includes('search-events')) {
    return {
      params: {
        target: 'events',
      },
    };
  }
  return {
    params: {
      target: 'jobs',
    },
  };
}

export default function ({ inputSelector, $loadingContainer, customParams }) {
  return {
    selector: inputSelector,
    debounce: 300,
    threshold: 2,
    placeHolder: 'Start searching...',
    searchEngine: 'loose',
    resultsList: {
      maxResults: 10,
      noResults: true,
      tabSelect: true,
    },
    resultItem: {
      highlight: true,
    },
    data: {
      keys: ['name'],
      cache: false,
      src: async (query) => {
        try {
          console.log('global-autocomplete:load-results');

          // Loading placeholder text
          $loadingContainer.classList.add('active');

          let params = {
            params: { s: query, target: 'jobs' },
          };

          // Search fields that do not have a search type selector eg jobs, companies, reviews, events
          if (inputSelector !== '#js-global-autocomplete-field-review') {
            customParams = getJobTypeSuggestionsParams();
          }

          if (customParams) {
            params = { params: { ...params.params, ...customParams.params } };
          }
          const response = await axios.get('/suggestions.php', params);

          // loading state OFF
          $loadingContainer.classList.remove('active');

          console.log([...response.data.data.companies, ...response.data.data.jobRoles]);

          // if (customParams) {
          //   if (customParams.params.exclude && customParams.params.exclude === 'jobRoles') {
          //     return [...response.data.data.companies];
          //   }
          // }

          // Returns Fetched data
          return [...response.data.data.companies, ...response.data.data.jobRoles];
        } catch (error) {
          return error;
        }
      },
    },
  };
}
