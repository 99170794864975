import axios from '../../axios';

export default {
  getJobs({ state, commit, getters }, { searchUrl }) {
    if (state.ui.loading) {
      return false;
    }

    const queryParams = {
      params: {},
      timeout: 5000,
    };

    const requestUrl = getters.requestUrl(searchUrl);

    commit('updateUi', { loading: true });

    return new Promise((resolve, reject) => {
      return axios.get(requestUrl, queryParams)
        .then((response) => {
          commit('updateUi', { loading: false });
          if (response.data.meta.total_results === 0) {
            commit('updateUi', { showWidget: false });
            return false;
          }

          commit('updateRemote', {
            results: response.data,
            count: response.data.meta.total_results,
            categories: response.data.meta.aggregations.filtered_by_parent_role,
          });

          return resolve(response);
        })
        .catch((error) => {
          commit('updateUi', { loading: false, showWidget: false });
          console.log(error);
          return reject(error);
        });
    });
  },
};
