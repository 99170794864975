/* eslint-disable camelcase */
const gtag = {
  send({
    event = null,
    category = null,
    action = null,
    id = null,
    category_id = null,
    value = null,
    location = null,
  }) {
    if (category === null && value !== null) {
      category = value;
    }

    location = location ?? window.location.href;

    console.log(`gtag-event::${event}`, {
      category, action, id, category_id, location,
    });

    try {
      window.dataLayer.push({
        event, category, action, id, category_id, location,
      });
    } catch (e) {
      console.log(e);
    }
  },
};

// eslint-disable-next-line no-alert
Object.assign(window, { gtag });

export default gtag;

console.log('gtag: loaded');
