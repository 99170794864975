// Lib: https://tarekraafat.github.io/autoComplete.js/
// Ref: https://tarekraafat.github.io/autoComplete.js/#/playground
//
// Notes:
// replaces resources/assets/vue/components/common/global-search-bar.vue
// implementation as its gets flagged by google lighthouse for LCP

import AutoComplete from '@tarekraafat/autocomplete.js';
import generateConfig from './autocomplete-config';

const $container = document.querySelector('#js-global-autocomplete');
const $form = document.querySelector('.SiteSearch-form');

function handleFormSubmission() {
  const textInput = $container.querySelector('#js-global-autocomplete-field');
  const searchTypeInput = $container.querySelector('.Form-select#show');

  const listOfPossibleSearchUrls = {
    jobs: '/search-jobs',
    reviews: '/search-reviews',
    companies: '/search-companies',
    events: '/search-events',
  };

  // // Disable the show input because we don't want it to be included in the form submission
  // searchTypeInput.disabled = true;

  if (textInput.value.trim() === '') {
    textInput.disabled = true;
  }

  $form.action = !listOfPossibleSearchUrls[searchTypeInput.value] ? 'search-jobs' : listOfPossibleSearchUrls[searchTypeInput.value];
  $form.submit();
}

function setupGlobalAutocomplete() {
  const inputSelector = '#js-global-autocomplete-field';

  const $loadingContainer = $container.querySelector('.LoadingScreen');

  const config = generateConfig({ inputSelector, $loadingContainer });

  const autoCompleteJS = new AutoComplete(config);

  autoCompleteJS.input.addEventListener('init', (event) => {
    console.log('global-autocomplete:init', event);
  });

  autoCompleteJS.input.addEventListener('selection', (event) => {
    console.log('global-autocomplete:selected', event.detail);

    autoCompleteJS.input.value = event.detail.selection.value.name;

    handleFormSubmission();
  });

  $container.querySelector('#js-global-autocomplete-field').addEventListener('keydown', (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleFormSubmission();
    }
  });

  // Handle manual seach submitting, where the user actually clicks the "Search" button
  $container.querySelector('.SiteSearch-form-button').addEventListener('click', (event) => {
    event.preventDefault();
    handleFormSubmission();
  });
}

if ($container !== null) {
  console.log('global-autocomplete:present');
  setupGlobalAutocomplete();
}
