/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

import axiosLibrary from 'axios';
import EventBus from './event-bus';

const axiosConfig = {
  headers: {
    common: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  },
};

const AxiosPlugin = axiosLibrary.create(axiosConfig);

AxiosPlugin.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.message === 'cancelled-request') {
    return Promise.reject(error);
  }

  if ([401, 412].indexOf(error.response.status) > -1) {
    EventBus.$emit('bus:axios-interceptor:requires-reauthentication');
  }
  return Promise.reject(error);
});

export default AxiosPlugin;
